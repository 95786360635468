import React from 'react';

const XL = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="28.243"
			height="28.243"
			viewBox="0 0 28.243 28.243"
			{...props}
		>
			<g id="close_button" data-name="close button" transform="translate(2.121 2.121)">
				<line
					id="Line_2"
					data-name="Line 2"
					y2="33.941"
					transform="translate(24 0) rotate(45)"
					fill="none"
					strokeLinecap="round"
					strokeWidth="3"
				/>
				<line
					id="Line_3"
					data-name="Line 3"
					y2="33.941"
					transform="translate(0 0) rotate(-45)"
					fill="none"
					strokeLinecap="round"
					strokeWidth="3"
				/>
			</g>
		</svg>
	);
};

export default XL;
