import React from 'react';

const CarrotDown = () => {
	return (
		<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.53542 0.981192C7.2737 0.716296 6.84606 0.716063 6.58406 0.980673L4.7106 2.87276C4.31929 3.26796 3.68071 3.26796 3.2894 2.87276L1.41594 0.980673C1.15394 0.716063 0.726299 0.716296 0.464582 0.981192C0.207098 1.2418 0.207098 1.66103 0.464582 1.92164L3.28864 4.78C3.68012 5.17623 4.31988 5.17623 4.71136 4.78L7.53542 1.92164C7.7929 1.66103 7.7929 1.2418 7.53542 0.981192Z" fill="currentColor" />
		</svg>

	);
};

export default CarrotDown;
