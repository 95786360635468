import React from 'react';

const CarrotLeft = () => {
	return (
		<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6.77822 1.19692C7.17556 1.58949 7.17591 2.23095 6.77899 2.62395L3.94087 5.43414C3.34808 6.0211 3.34808 6.97895 3.94088 7.56591L6.77899 10.3761C7.17591 10.7691 7.17556 11.4106 6.77821 11.8031C6.3873 12.1894 5.75846 12.1894 5.36755 11.8031L1.08003 7.56706C0.485683 6.97985 0.485682 6.0202 1.08003 5.43298L5.36755 1.19692C5.75846 0.810695 6.3873 0.810694 6.77822 1.19692Z" fill="currentColor" />
		</svg>

	);
};

export default CarrotLeft;
