export const emptyShopifyAddress = {
	address1: '',
	address2: '',
	city: '',
	company: '',
	country: '',
	countryCodeV2: '',
	firstName: '',
	id: '',
	lastName: '',
	phone: '',
	province: '',
	provinceCode: '',
	zip: '',
};

export const emptySalesforceAddress = {
	name: '',
	address1: '',
	address2: '',
	city: '',
	province: '',
	zip: '',
	country: '',
	phone: '',
	phone_secondary: '',
};

export const emptyAddress = {
	shopifyId: '',
	firstName: '',
	lastName: '',
	company: '',
	address1: '',
	address2: '',
	city: '',
	state: '',
	zip: '',
	phone: '',
	secondaryPhone: '',
	website: '',
};

export const emptyStorefront = {
	name: '',
	url: '',
	storePickup: true,
};

export const emptyDBA = {
	company: '',
	address1: '',
	address2: '',
	city: '',
	state: '',
	zip: '',
	phone: '',
	secondaryPhone: '',
	email: '',
	billing: {
		sameAsPhysical: true,
		address1: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
	},
};

export const emptyFulfillmentCenter = {
	// company: '',
	address1: '',
	address2: '',
	city: '',
	state: '',
	zip: '',
	phone: '',
	// email: '',
};

export const practiceTypeOptions = [
	{ value: 'CN', label: 'Ceritfied Nutritionist' },
	{ value: 'DC', label: 'Doctor of Chiropractic ' },
	{ value: 'DDS', label: 'Dentist' },
	{ value: 'OD', label: 'Doctor of Optometry' },
	{ value: 'DO', label: 'Doctor of Ostheopathic Medicine' },
	{ value: 'DPM', label: 'Doctor of Podiatric Medicine' },
	{ value: 'Herbalist', label: 'Herbalist' },
	{ value: 'Internal Med', label: 'Internal Medicine' },
	{ value: 'LAC/OMD', label: 'Licensed Acupuncturist/Oriental Medicine Doctor' },
	{ value: 'LMT', label: 'Licensed Massage Therapist' },
	{ value: 'MD', label: 'Medical Doctor' },
	{ value: 'Mental Health', label: 'Mental Health' },
	{ value: 'ND', label: 'Naturopathic Doctor' },
	{ value: 'NTP', label: 'Nutritional Therapy Practitioner' },
	{ value: 'NURSING', label: 'Nursing' },
	{ value: 'Other', label: 'Other Licensed Healthcare Provider' },
	{ value: 'PA', label: 'Physician\'s Assistant' },
	{ value: 'Pharm', label: 'Pharmacist' },
	{ value: 'PHD', label: 'PhD' },
	{ value: 'PT/KINS', label: 'Physical Therapist/Kinesiotherapists' },
	{ value: 'RD', label: 'Registered Dietician' },
	{ value: 'Student', label: 'Student' },
	{ value: 'VET', label: 'Veterinarian' },
];

export const specialtyOptions = [
	{ value: 'Not Applicable', label: 'Not Applicable' },
	{ value: 'Acupuncture', label: 'Acupuncture' },
	{ value: 'Autism', label: 'Autism' },
	{ value: 'Cardiology', label: 'Cardiology' },
	{ value: 'Crossfit', label: 'Crossfit' },
	{ value: 'Dermatology', label: 'Dermatology' },
	{ value: 'Endocrinology', label: 'Endocrinology' },
	{ value: 'Family Medicine', label: 'Family Medicine' },
	{ value: 'Gastroenterology', label: 'Gastroenterology' },
	{ value: 'Geriatrics', label: 'Geriatrics' },
	{ value: 'Immunology', label: 'Immunology' },
	{ value: 'Mental Health', label: 'Mental Health' },
	{ value: 'OBGYN', label: 'OBGYN' },
	{ value: 'Obstetrics', label: 'Obstetrics' },
	{ value: 'Oncology', label: 'Oncology' },
	{ value: 'Ophthalmology', label: 'Ophthalmology' },
	{ value: 'Orthopedics', label: 'Orthopedics' },
	{ value: 'Other', label: 'Other' },
	{ value: 'Pediatrics', label: 'Pediatrics' },
	{ value: 'Rehabilitation Medicine', label: 'Rehabilitation Medicine' },
	{ value: 'Rheumatology', label: 'Rheumatology' },
	{ value: 'Surgery', label: 'Surgery' },
];

export const stateOptions = [
	{ value: 'AL', label: 'Alabama' },
	{ value: 'AK', label: 'Alaska' },
	{ value: 'AR', label: 'Arkansas' },
	{ value: 'AZ', label: 'Arizona' },
	{ value: 'AR', label: 'Arkansas' },
	{ value: 'CA', label: 'California' },
	{ value: 'CO', label: 'Colorado' },
	{ value: 'CT', label: 'Connecticut' },
	{ value: 'DE', label: 'Delaware' },
	{ value: 'DC', label: 'District Of Columbia' },
	{ value: 'FL', label: 'Florida' },
	{ value: 'GA', label: 'Georgia' },
	{ value: 'HI', label: 'Hawaii' },
	{ value: 'ID', label: 'Idaho' },
	{ value: 'IL', label: 'Illinois' },
	{ value: 'IN', label: 'Indiana' },
	{ value: 'IA', label: 'Iowa' },
	{ value: 'KS', label: 'Kansas' },
	{ value: 'KY', label: 'Kentucky' },
	{ value: 'LA', label: 'Louisiana' },
	{ value: 'ME', label: 'Maine' },
	{ value: 'MD', label: 'Maryland' },
	{ value: 'MA', label: 'Massachusetts' },
	{ value: 'MI', label: 'Michigan' },
	{ value: 'MN', label: 'Minnesota' },
	{ value: 'MS', label: 'Mississippi' },
	{ value: 'MO', label: 'Missouri' },
	{ value: 'MT', label: 'Montana' },
	{ value: 'NE', label: 'Nebraska' },
	{ value: 'NV', label: 'Nevada' },
	{ value: 'NH', label: 'New Hampshire' },
	{ value: 'NJ', label: 'New Jersey' },
	{ value: 'NM', label: 'New Mexico' },
	{ value: 'NY', label: 'New York' },
	{ value: 'NC', label: 'North Carolina' },
	{ value: 'ND', label: 'North Dakota' },
	{ value: 'OH', label: 'Ohio' },
	{ value: 'OK', label: 'Oklahoma' },
	{ value: 'OR', label: 'Oregon' },
	{ value: 'PA', label: 'Pennsylvania' },
	{ value: 'RI', label: 'Rhode Island' },
	{ value: 'SC', label: 'South Carolina' },
	{ value: 'SD', label: 'South Dakota' },
	{ value: 'TN', label: 'Tennessee' },
	{ value: 'TX', label: 'Texas' },
	{ value: 'UT', label: 'Utah' },
	{ value: 'VT', label: 'Vermont' },
	{ value: 'VA', label: 'Virginia' },
	{ value: 'WA', label: 'Washington' },
	{ value: 'WV', label: 'West Virginia' },
	{ value: 'WI', label: 'Wisconsin' },
	{ value: 'WY', label: 'Wyoming' },
];

export const webinarRefOptions = [
	{ value: 'Email', label: 'Email' },
	{ value: 'Facebook', label: 'Facebook' },
	{ value: 'Instagram', label: 'Instagram' },
	{ value: 'LinkedIn', label: 'LinkedIn' },
	{ value: 'Twitter', label: 'Twitter' },
	{ value: 'YouTube', label: 'YouTube' },
	{ value: 'Friend', label: 'Friend' },
	{ value: 'Colleague', label: 'Colleague' },
	{ value: 'Other', label: 'Other' },
];
