import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';

const ns = `banner`;

const Banner = ({
	content,
	bgColor,
	textColor,
	fontSize,
	fontWeight,
	letterSpacing,
	width,
	textAlign,
	consumerSiteLinkOut,
}) => {
	const {label, url } = consumerSiteLinkOut;
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ fontSize }`]: !!fontSize,
	});

	return (
		<div
			className={`${ rootClassnames } container-fluid`}
			style={{
				backgroundColor: bgColor,
				color: textColor,
				fontWeight,
				textAlign,
			}}
		>
			{label && url && (
				<span className={`${ ns }__consumer-site-link`}>
					<a href={url} target="_blank" rel="noreferrer">
						{label}
					</a>
				</span>
			)}
			<span
				className={`${ ns }__content block mx-auto`}
				style={{
					width: width ? `${ width }rem` : '',
					letterSpacing: letterSpacing ? `${ letterSpacing }rem` : '',
				}}
			>
				{parse(content)}
			</span>
		</div>
	);
};

export default Banner;
