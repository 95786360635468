import React from 'react';

const Checkbox = () => {
	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.5"
				y="0.5"
				width="11"
				height="11"
				rx="2.5"
				stroke="currentColor"
			/>
			<rect
				x="3.5"
				y="3.5"
				width="5"
				height="5"
				rx="0.5"
				fill="currentColor"
				stroke="currentColor"
			/>
		</svg>
	);
};

export default Checkbox;
