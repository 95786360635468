import React from 'react';

const Menu = () => {
	return (
		<svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="25" height="3" fill="currentColor" />
			<rect y="9" width="25" height="3" fill="currentColor" />
			<rect y="18" width="25" height="3" fill="currentColor" />
		</svg>
	);
};

export default Menu;
