import React, {
	createContext,
	useState,
	useEffect,
	useContext,
} from 'react';
import fetch from 'isomorphic-fetch';
import Client from 'shopify-buy';
import isEmpty from 'lodash.isempty';
import { TrackingContext } from './tracking-context';
import { AuthContext } from './auth-context';
import { shopify } from '../utils/clients';
import { tryParse, isPlainObject, convertToElevarDataItem, getElevarListValue } from '../utils';

const client = Client.buildClient(
	{
		domain: process.env.GATSBY_SHOPIFY_STORE_URL,
		storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
		apiVersion: process.env.GATSBY_SHOPIFY_STOREFRONT_API_VERSION,
	},
	fetch
);

const defaultValues = {
	selectedVariant: null,
	isOpen: false,
	loading: false,
	cartLoading: true,
	onOpen: () => {},
	onClose: () => {},
	onFlyoutCartOpen: () => {},
	onFlyoutCartClose: () => {},
	addVariantToCart: () => {},
	removeLineItem: () => {},
	updateLineItem: () => {},
	elevarUserDataEvent: () => {},
	client,
	checkout: {
		lineItems: [],
	},
	cart: {
		lineItems: [],
	},
};

export const StoreContext = createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
// const localStorageKey = `shopify_checkout_id`;
const localStorageCartKey = 'shopify_cart_id';

export const StoreProvider = ({ children }) => {
	const { actions: trackingActions } = useContext(TrackingContext);
	const { state: authState } = useContext(AuthContext);
	const { userProperties, userPropertiesLoaded } = authState;
	const [loadMoreState, setLoadMoreState] = React.useState({});
	const [cart, setCart] = useState(defaultValues.cart);
	const [selectedVariant, setSelectedVariant] = React.useState(null);
	const [loading, setLoading] = useState(false);
	const [cartLoading, setCartLoading] = useState(true);
	const [didJustAddToCart, setDidJustAddToCart] = useState(false);
	const [isFlyoutCartOpen, setIsFlyoutCartOpen] = React.useState(() => {
		// If cart-open=true, open cart flyout on page load
		const params = new URLSearchParams(window.location.search);
		return params.get('cart-open') === 'true';
	});

	const setCartItem = (shopifyCart, dictionary) => {
		if (isBrowser && dictionary) {
			localStorage.setItem(localStorageCartKey, JSON.stringify(dictionary));
		}

		setCart(shopifyCart);
	};

	useEffect(() => {
		setCartLoading(true);
		(async () => {
			try {
				const email = authState?.auth?.profile?.email || null;
				const identifier = String(email);
				const trackingParams = trackingActions.getTrackingObj();
				const fetchOptions = { params: trackingParams };

				const storageValue = isBrowser
					? localStorage.getItem(localStorageCartKey)
					: null;

				let dictionary = tryParse(storageValue) || {};

				if (!isPlainObject(dictionary)) {
					dictionary = {};
				}

				let cartId = dictionary[identifier];

				if (email && !cartId) {
					cartId = dictionary.null;
					dictionary[identifier] = cartId;
					dictionary.null = undefined;
				}

				const getOrCreateCart = cartId
					? shopify.getCart.bind(null, cartId)
					: shopify.createCart.bind(null, undefined);
				let shopifyCart = await getOrCreateCart(fetchOptions);

				if (isEmpty(shopifyCart)) {
					shopifyCart = await shopify.createCart(undefined, fetchOptions);
				}

				dictionary[identifier] = shopifyCart.id;
				setCartItem(shopifyCart, dictionary);
			} catch (error) {
				console.error(error.json || error);
				setCartItem(defaultValues.cart, {});
			}
			setCartLoading(false);
		})();
	}, [authState?.auth?.profile?.email, trackingActions.getTrackingObj]);

	const addVariantToCart = (
		variantId,
		quantity,
		sellingPlanId,
		sellingPlanGroup,
		product,
		location
	) => {
		setLoading(true);
		setSelectedVariant(variantId);

		const cartID = cart.id;
    const lineItemsToUpdate = [
      {
        merchandiseId: variantId,
        quantity: parseInt(quantity, 10),
		sellingPlanId,
        // Adds customAttributes used for Recharge subcription cart rendering
        ...(sellingPlanId &&
          sellingPlanGroup && {
            attributes: [
              {
                key: '_sellingPlanId',
                value: `${sellingPlanId}`,
              },
              {
                // adding this for easy access across the application
                key: '_sellingPlanGroup',
                value: JSON.stringify(sellingPlanGroup),
              },
            ],
          }),
      },
    ];

    return shopify
      .addCartLines(cartID, lineItemsToUpdate)
      .then(res => {
        setCart(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => {
          return setDidJustAddToCart(false);
        }, 250);

				if (userPropertiesLoaded) {
					window.ElevarDataLayer = window.ElevarDataLayer ?? [];
					window.ElevarDataLayer.push({
						event: 'dl_add_to_cart',
						user_properties: userProperties,
						ecommerce: {
							currencyCode: 'USD',
							add: {
								actionField: {
									list: getElevarListValue({ item: product, location }),
								},
								products: [
									convertToElevarDataItem({
										item: product,
										location,
										quantity,
										variant: variantId,
									}),
								],
							},
						},
					});
				}
      })
      .catch(error => {
        console.error(error.json || error.message);
      });
	};

	const removeLineItem = (cartID, lineItemID, item, location, index) => {
		setLoading(true);

    return shopify
      .removeCartLines(cartID, [lineItemID])
      .then(res => {
        setCart(res);
        setLoading(false);
				if (userPropertiesLoaded) {
					window.ElevarDataLayer = window.ElevarDataLayer ?? [];
					window.ElevarDataLayer.push({
						event: 'dl_remove_from_cart',
						user_properties: userProperties,
						ecommerce: {
							currencyCode: 'USD',
							remove: {
								actionField: {
									list: getElevarListValue({ item, location }),
								},
								products: [convertToElevarDataItem({ item, index, location })],
							},
						},
					});
				}
      })
      .catch(error => {
        console.error(error.json || error.message);
      });
	};

	const updateLineItem = (cartID, lineItemID, quantity, location) => {
		setLoading(true);

		const lineItemsToUpdate = [
			{ id: lineItemID, quantity: parseInt(quantity, 10) },
		];

    return shopify
      .updateCartLines(cartID, lineItemsToUpdate)
      .then(res => {
        setCart((previousCart) => {
					const previousLineItem =
						previousCart.lineItems.find(item => item.id === lineItemID);
					const updatedLineItem =
						res.lineItems.find(item => item.id === lineItemID);
					const updatedLineItemIndex =
						res.lineItems.findIndex(item => item.id === lineItemID);

					if (previousLineItem && updatedLineItem) {
						const previousQuantity = parseInt(previousLineItem.quantity, 10);
						const updatedQuantity = parseInt(updatedLineItem.quantity, 10);
						const quantityChange = updatedQuantity - previousQuantity;

						if (quantityChange !== 0) {
							const eventType = quantityChange > 0 ? 'dl_add_to_cart' : 'dl_remove_from_cart';
							const operation = quantityChange > 0 ? 'add' : 'remove';
							const absQuantityChange = Math.abs(quantityChange);

							window.ElevarDataLayer = window.ElevarDataLayer || [];
							window.ElevarDataLayer.push({
								event: eventType,
								user_properties: userProperties,
								ecommerce: {
									currencyCode: 'USD',
									[operation]: {
										actionField: {
											list:
												getElevarListValue({
													item: updatedLineItem,
													location,
												}),
										},
										products: [convertToElevarDataItem({
											item: updatedLineItem,
											index: updatedLineItemIndex,
											quantity: absQuantityChange,
											location,
										})],
									},
								},
							});
						}
					}
					return res;
				});
        setLoading(false);
      })
      .catch(error => {
        console.error(error.json || error.message);
      });
	};

	const onFlyoutCartOpen = ({ location }) => {
		if (!isFlyoutCartOpen) setIsFlyoutCartOpen(true);
		if (userPropertiesLoaded && Array.isArray(cart?.lineItems)) {
			const impressions = cart.lineItems.map((item, index) => {
				return convertToElevarDataItem({ item, index, location });
			});

			window.ElevarDataLayer = window.ElevarDataLayer || [];
			window.ElevarDataLayer.push({
				event: 'dl_view_cart',
				cart_total: cart?.cost?.subtotalAmount?.amount || '',
				user_properties: userProperties,
				ecommerce: {
					currencyCode: 'USD',
					actionField: {
						list: 'Shopping Cart',
					},
					impressions,
				},
			});
		}
	};

	const onFlyoutCartClose = () => {
		setIsFlyoutCartOpen(false);
	};

	const addLoadMoreState = (key, numberOfVisibleItems) => {
		if (key) {
			setLoadMoreState(prevState => {
				const newValue = {
					...prevState,
				};
				if (newValue[`${key}`]) {
					if (newValue[`${key}`] < numberOfVisibleItems) {
						newValue[`${key}`] = numberOfVisibleItems;
					}
				} else {
					newValue[`${key}`] = numberOfVisibleItems;
				}
				return newValue;
			});
		}
	};

	const elevarUserDataEvent = (location) => {
		const cartItems = cart.lineItems.map((item, index) => {
			return convertToElevarDataItem({ item, index, location });
		});

		window.ElevarDataLayer = window.ElevarDataLayer || [];
		window.ElevarDataLayer.push({
			event: 'dl_user_data',
			cart_total: cart?.cost?.subtotalAmount?.amount?.toString() || '',
			user_properties: userProperties,
			ecommerce: {
				currencyCode: 'USD',
				cart_contents: {
					products: cartItems,
				},
			},
		});
	};

	return (
		<StoreContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				...defaultValues,
				addVariantToCart,
				didJustAddToCart,
				selectedVariant,
				removeLineItem,
				updateLineItem,
				isFlyoutCartOpen,
				onFlyoutCartOpen,
				onFlyoutCartClose,
				elevarUserDataEvent,
				loadMoreState,
				addLoadMoreState,
				cart,
				loading,
				cartLoading,
				checkout: cart,
			}}
		>
			{children}
		</StoreContext.Provider>
	);
};
