/* eslint-disable no-underscore-dangle */

import React, { useContext } from 'react';
import classNames from 'classnames';

import { formatPrice } from '../../utils';

import Modal from '../Modal';
import Button from '../Button';
import LineItem from '../LineItem';

import { StoreContext } from '../../context/store-context';
import { AuthContext } from '../../context/auth-context';
import { Nordic } from '../../utils/clients';
import CartProductSlider from '../CartProductSlider';

const ns = `cart-flyout`;

const CartFlyout = ({ location }) => {
	const {
		checkout,
		loading,
		isFlyoutCartOpen,
		onFlyoutCartClose,
	} = useContext(StoreContext);
	const { state: authState } = useContext(AuthContext);
	const { idToken } = authState.auth;

	const emptyCart = checkout.lineItems.length === 0;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const calculateSubtotal = () => {
		let subtotal = 0;
		let currency;

		checkout.lineItems.forEach(item => {
			const customAttrs = {};
			const {
				quantity,
				variant: {
					price: {
						amount: price,
						currencyCode,
					},
				},
			} = item;

			const numericPrice = Number(price);

			if (!currency) {
				currency = currencyCode;
			}

			item.customAttributes.forEach(({ key, value }) => {
				customAttrs[key] = value;
			});

			if (customAttrs?._sellingPlanId && customAttrs?._sellingPlanGroup) {
				const spg = JSON.parse(customAttrs._sellingPlanGroup);

				const {
					discount_amount: discountAmount,
				} = spg.include.product;

				const discount = discountAmount / 100;

				subtotal += ((numericPrice - (numericPrice * discount)) * quantity);
			} else {
				subtotal += numericPrice * quantity;
			}
		});

		return formatPrice(currency, subtotal);
	};

	// If no subscription items present, send user to Shopify
	// checkout, otherwise send to Shopify /cart page

	const getRedirectURL = async (checkoutURL) => {
		try {
			if (!idToken) {
				return checkoutURL;
			}

			const nordic = new Nordic(idToken);
			const { multipassURL } = await nordic.getMultipassURL(checkoutURL);
			return multipassURL || checkoutURL;
		} catch (error) {
			console.error(error.json || error);
			return checkoutURL;
		}
	};

	const handleCheckout = async () => {
		const redirectURL = await getRedirectURL(checkout.webUrl);
		window.open(redirectURL, '_self');
	};

	return (
		<Modal
			modifier={rootClassnames}
			onRequestClose={() => { onFlyoutCartClose(); }}
			isOpen={isFlyoutCartOpen}
		>
			<div className={`${ ns }__header`}>
				<h4>Your Bag</h4>
			</div>
			<div className={`${ ns }__body`}>
				{emptyCart
					? (
						<div className={`${ ns }__empty`}>
							<p>You have no items in your bag.</p>
						</div>
					) : (
						<ul className={`${ ns }__line-items`}>
							{checkout.lineItems.map((item, index) => {
								return (
									<LineItem
										item={item}
										key={item.id}
										location={location}
										index={index}
									/>
								);
							})}
						</ul>
					)}
			</div>
			<div className={`${ ns }__footer`}>
				<CartProductSlider
					onFlyoutCartClose={onFlyoutCartClose}
					location={location}
				/>
				{!emptyCart && (
					<>
						<div className={`${ ns }__totals`}>
							<div className={`${ ns }__totals-total`}>
								<p>Subtotal</p>
								<p>{calculateSubtotal()}</p>
							</div>
							<div className={`${ ns }__totals-message`}>
								<p />
								<p>Tax, shipping, and discounts calculated at checkout</p>
							</div>
						</div>
						<div className={`${ ns }__checkout`}>
							<Button
								onClick={handleCheckout}
								disabled={loading}
							>
								{loading ? 'Updating…' : 'Checkout'}
							</Button>
						</div>
					</>
				)}
			</div>
		</Modal>
	);
};

export default CartFlyout;
