import React from 'react';

const SearchIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M17.3645 14.3208H16.5515L16.2633 14.0429C17.2719 12.8696 17.8791 11.3465 17.8791 9.68954C17.8791 5.99485 14.8842 3 11.1895 3C7.49485 3 4.5 5.99485 4.5 9.68954C4.5 13.3842 7.49485 16.3791 11.1895 16.3791C12.8465 16.3791 14.3696 15.7719 15.5429 14.7633L15.8208 15.0515V15.8645L20.9666 21L22.5 19.4666L17.3645 14.3208ZM11.1895 14.3208C8.62693 14.3208 6.55832 12.2521 6.55832 9.68954C6.55832 7.12693 8.62693 5.05832 11.1895 5.05832C13.7521 5.05832 15.8208 7.12693 15.8208 9.68954C15.8208 12.2521 13.7521 14.3208 11.1895 14.3208Z" fill="currentColor" />
		</svg>
	);
};

export default SearchIcon;
